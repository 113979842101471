<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div>
      <!-- <b-row><b-col class="ft-weight"> </b-col></b-row> -->
      <div>
        <h1 class="mr-sm-4 header-tablepage">PRIVACY POLICY</h1>
      </div>

      <div class="title-panel mt-3">PRIVACY POLICY SETTING DETAIL</div>
      <b-container class="no-gutters bg-white">
        <b-row>
          <b-col class="mt-3">
            <TextEditorsTiny
              v-model="form.template"
              :value="form.template"
              @onDataChange="(val) => (form.template = val)"
              textFloat=""
              placeholder="PRIVACY POLICY"
            />
          </b-col>
        </b-row>
      </b-container>

      <!-- <div class="footer">
        <b-row>
          <b-col md="6">
            <b-button
              class="btn-cancel-automation"
              @click.prevent="closeAutomation"
              >ยกเลิก</b-button
            >
          </b-col>
          <b-col class="text-right" md="6">
            <b-button
              class="btn-confirm-automation"
              @click.prevent="UpdatePrivacyPolicy()"
              >บันทึก</b-button
            >
          </b-col>
        </b-row>
      </div> -->
      <FooterAction @submit="UpdatePrivacyPolicy" routePath="/setting" />
    </div>
  </div>
</template>

<script>
import TextEditorsTiny from "@/components/inputs/TextEditorsTiny";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    TextEditorsTiny,
    OtherLoading,
  },
  data() {
    return {
      form: {
        template: null,
        version: 0,
        status: 0,
      },
      updatePolicy: {},
      isLoading: true,
      detailPolicy: {},
    };
  },
  async created() {
    await this.getDetailPolicy();
  },
  methods: {
    async getDetailPolicy() {
      await this.$store.dispatch("getDetailPolicy");
      const data = this.$store.state.setting.stateDetailPolicy;
      if (data.result === 1) {
        this.form.template = data.detail;
        this.isLoading = false;
      }
    },
    async UpdatePrivacyPolicy() {
      await this.$store.dispatch("UpdatePrivacyPolicy", this.form);
      const data = this.$store.state.setting.stateUpdatePrivacyPolicy;
      if (data.result === 1) {
        this.$swal("Update Success!", {
          icon: "success",
        }).then(function () {
          window.location.href = `/setting/policy`;
        });
        this.updatePolicy = data.detail;
        this.isLoading = false;
      }
    },
    closeAutomation() {
      this.$router.push("/setting/policy");
    },
  },
};
</script>

<style lang="scss" scoped>
.ft-weight {
  font-weight: 600;
  font-size: 16px;
}
.bg-purple {
  text-align: left;
  border: none;
  background: var(--secondary-color);
  border-radius: 0px;
  color: #000;
  font-weight: 600;
  padding: 10px 0;
  font-size: 13px;
}
::v-deep .text-editor-custom {
  margin-top: -22px;
}
.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  padding: 10px;
  background: #fff;
}
.btn-cancel-automation {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.space-1 {
  margin-top: 50px;
}
</style>
